<template>
  <div class="page_container">
    <div class="banner">
      <div class="page-container">
        <div
          class="back"
          @click="handleBack"
        ><i class="el-icon-arrow-left"></i>返回</div>
        <p
          class="title"
          v-if="!$store.state.isPhone"
        >所有产品</p>

        <div>
          <div class="title-1">中智软创石油化工/物联网/工业制造管理系统</div>
          <div class="desc-1">丰富的创新经验</div>
          <div
            class="search"
            v-if="!$store.state.isPhone"
          >
            <el-input
              v-model="value1"
              placeholder="请输入产品名称或关键字搜索"
              style="width:500px;margin-right:10px"
              @change="handleSearch"
            ></el-input>
            <el-select
              v-model="selectVal"
              placeholder="请选择"
              style="margin-right:10px"
              @change="handleSelect"
            >
              <el-option
                v-for="item in list1"
                :key="item.title"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>

            <el-button
              type="primary"
              icon="el-icon-search"
            >搜索</el-button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page-tab page-grey"
      v-if="$store.state.isPhone"
    >
      <div
        v-for="item in list1"
        :key="item.title"
        @click="handleSelect(item.title)"
        :style="`background-color:${item.title==selectVal?'#264bf8':'#ffffff'};color:${item.title==selectVal?'#ffffff':'#323232'}`"
        class="pro-tab"
      >{{item.title}}</div>
    </div>

    <div
      class="page1 page-grey"
      ref="page1"
    >
      <div class="page-container">
        <p
          v-if="!$store.state.isPhone"
          :style="`font-size:1.625em;padding:35px 0;${$store.state.isPhone&&'padding-left:30px'}`"
        >所有产品</p>
        <el-row :gutter="35">
          <el-col
            v-for="(item1,i2) in searchList"
            :key="i2"
            :style="`margin-bottom:35px;${$store.state.isPhone&&'padding-left:30px;padding-right:30px'}`"
            class="page1-item"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="8"
            :xl="8"
          >
            <el-card
              :body-style="{ padding: '0px'}"
              class="animate__animated animate__fadeInUp"
            >
              <div style="padding: 14px;">
                <span class="title">{{item1.title}}</span>
                <p class="desc">{{item1.desc}}</p>
                <div
                  class="btn animate__animated more"
                  @click="handleTo(item1)"
                >查看全部</div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <div class="page_pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="15"
            layout="prev, pager, next"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      banner_url: require("../../assets/solution/s-banner.png"),
      value1: '',
      pageIndex: 0,
      activeName: '中智工业智能MOM套件',
      selectVal: '中智工业智能MOM套件',

      list1: [
        {
          key: 0,
          title: '中智工业智能MOM套件',
          list: [
            { list: ['化工批次管理', '配煤数据校正', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '生产绩效管理'], title: '炼油行业MOM系统套件', desc: '炼油MOM系统以MES为核心实现生产业务内计划、调度、工艺、执行、统计、分析全过程的闭环管理，达成生产与能耗、计量、质量、安环、设备、仓储等领域的衔接，实现对生产过程中的工艺进行实时监控和预警、对物耗能耗进行全流程跟踪和分析、对产品质量进行全面管控、对生产绩效进行精准考核与评价。' },
            { list: ['化工批次管理', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '质量管理', '生产绩效管理', '安全环保管理', '统计报表'], title: '石油化工行业MOM系统套件', desc: '以化工产品批次管理为主线，重点对新产品研发、原料配比、工艺方案、生产排程、生产过程批次跟踪、客户需求反馈等化工生产全流程进行监控和分析，实现企业业务流程的优化和生产过程的精细化管理，指导企业合理配置资源、科学调度排产，节能降耗、挖潜增效，提升对市场变化和突发异常的应变能力，使企业生产运行处于“安稳长满优”状态。' },
            { list: ['化工批次管理', '配煤数据校正', '实时数据库', '计划管理', '调度管理', '工艺管理', '操作管理', '设备管理', '计量管理', '物料管理', '质量管理', '三剂管理', '能源管理', '生产绩效管理', '安全环保管理', '统计报表'], title: '煤化工行业MOM系统套件', desc: '以煤化工全产业链为背景，通过计划、调度、节能降耗、优化操作、装置绩效管理等手段实现优化生产、提高装置运行平稳率，改进物料平衡，提高产品转化率、降低物耗能耗，降低环境污染，获得经济效益；通过对生产运行结果的数据分析，发现制约生产及经济效益的瓶颈问题，使企业管理向集约型和精细化转，实现生产的安稳长满优的目标。' },
            { list: ['客户管理', '合同管理', '船货计划', '气量计划', '气量调度', '气量监测', '库存监测', '管存计算', '气量平衡', '气量交接', '槽车计划', '能耗管理', '设备检维修', '异常管理', '绩效管理', '统计报表', '调度指挥', '生产成本管理', '移动应用'], title: 'LNG行业MOM系统套件', desc: '以LNG全产业链为视角，通过MOM套件应用，覆盖产、供、储、销各环节的生产调度及运营业务，实现数据采集自动化、过程运行可视化、生产管理数字化和智能化，为LNG接收站开展国际贸易、生产经营、保供调度等决策提供智能化的数据分析结果和数据辅助。' },
            { list: ['营销管理', '计划管理', '生产管理', '物料管理', '质量管理', '异常管理', '设备管理', '工艺技术管理', '生产看板', 'Andon管理', '刀具管理', '异常管理'], title: '机加工行业MOM系统套件', desc: '以订单及时交付为核心，实现从生产订单到产品完工的全部生产活动的优化管理，运用及时、准确的采集信息，指导、启动、响应并记录管理工厂的生产活动，从而能够对条件的变化做出迅速的响应、提高工厂运作过程的透明度和执行效率，降低生产库存、提高订单交付及时率、合格率，提高客户满意度，提升企业品牌形象和核心竞争力。' },
            { list: ['流程监控', '安环监控', '计划管理', '调度管理', '操作管理', '物料管理', '能源管理', '仓库管理', '配方管理', '批次管理', '质量管理', '生产统计', '移动应用'], title: 'PVC行业MOM系统套件', desc: '实现生产业务对计划、调度、工艺、执行、统计、分析全过程的闭环管理，实现对生产、能耗、计量、质量、安环、设备等领域的基本覆盖，充分发挥系统在企业生产运行管理中的核心作用；增强企业对生产现场的动态监控与生产指挥能力，提高生产运行效率和协同管理水平，为业务发展战略提供支撑。' },
            { list: ['计划排产管理', '车间作业管理', '质量管理', '设备管理', '模具管理', '物流仓储管理', '生产看板', '生产预警', '能耗分析', '统计报表', '移动应用'], title: '注塑行业MOM系统套件', desc: '针对注塑行业多品种、小批量、甚至单件的生产模式，通过条形码、RFID、数据采集器及扫描枪等数据采集手段,实时采集生产现场的机台运行的状况、模具的状态、在制品、物料、订单及品质等的信息，实现车间生产现场的透明化的管理，满足生产过程的追溯和管控的需要，并能与ERP/MRPII等信息系统对接和交互，提升生产相关职能部门的信息共享和协同的效率,提高了企业的核心的竞争力。' },
            {
              title: '智慧IMOO炼化工厂',
              desc: '采用了物联网、实时感知、海量信息处理、智能控制等技术,订单信息从ERP-IC卡-WMS-WCS自动传递,即通过RFIDIC卡,自动读取入库和出库信息,产品自动入库、出库,无人操作,全自动控制;承运商通过在线预约,自动排队, 短信提醒,即可自动提货;立体库会根据订单自行补货,自动灌装、封边、传输、码垛和装车,且自动清点盘库。',
            },
          ]
        },
        {
          key: 1,
          title: '中智工业生产管控软件',
          list: [
            { list: ['实时数据采集', '数据压缩和存储', '数据缓存和断点续传', '工艺流程图监测', '工艺数据报警', '工艺数据回放', '工艺数据分析', '设备状态监测', '安全环保监测', '能源管网监测', '工艺台账', '统计报表'], title: '实时数据库软件', desc: '集成全厂DCS、SIS、ITCC、GDS、PLC、SCADA等控制系统，实时采集工艺、质量、液位、浓度等过程数据，通过工艺流程图实现生产过程的实时监控、工艺/安全报警、工艺数据分析等业务应用。' },
            { list: ['设备台账管理', '设备档案管理', '检维修计划管理', '检维修工单管理', '故障管理', '设备启停管理', '设备运行管理', '润滑管理', '密封管理', '计量器具管理', '备品备件管理', '统计报表与绩效分析'], title: '设备全生命周期管理软件', desc: '以设备档案为核心，记录资产全生命周期中涉及到的建设、成本、文档、巡检、消缺、维修、运行、安全、退役等信息，并通过审批流程和待办业务功能，实现企业业务流程化、闭环化、精细化管理的需求。' },
            { list: ['计量体系管理', '计量点管理', '装置加工计量', '罐区收付计量', '能源产耗计量', '物料进出厂计量', '计量仪表监测', '计量器具管理', '计量人员管理', '计量统计报表'], title: '计量管理软件', desc: '针对进出厂、装置加工、能源产耗等，建立覆盖全厂的准确的计量和统计模型，对各个环节计量数据进行实时采集、比对和分析，及时发现进出厂、产耗环节中的异常损耗等，分析仪表异常、跑冒滴漏等可能原因，降低生产损耗，实现降本增效，提升企业市场竞争力。' },
            { list: ['物料主数据管理', '装置计量模型', '进出厂计量点管理', '自动装车系统集成', '地磅称重系统集成', '仓储系统集成', '进出厂计量', '装置投入产出计量', '罐区计量', '三剂/辅料计量', '物料移动管理', '物料平衡管理', '平衡报表'], title: '物料管理软件', desc: '针对生产型企业仓储管理，能够有效地应对工厂复杂的物料进出业务，提升物资流转的执行效率，并为完整的批次追溯和MES制程控制打下基础。WMS能够与MES、ERP深度无缝整合。' },
            { list: ['能源主数据管理', '能源计量点管理', '能耗设备管理', '能源计划管理', '临时用能管理', '能源数据采集', '能源数据审核', '能源数据平衡', '能耗对标管理', '能源产耗综合分析', '国家、省市能耗监测平台集成'], title: '能源管理软件', desc: '集成企业能源系统数据采集、处理和分析、控制和调度、平衡预测和管理等功能，降低重要能源介质放散，提高能源介质的回收和梯级利用水平，实现多能源介质的协同平衡与优化利用的企业能源管控系统协同，使企业能源绩效得以持续改进和保持。' },
            { list: ['综合展示', '生产运行监测', '消防/安防监测', '报警管理', '设备运行状态监测', '管线运行状态监测', '储罐运行监测', '公路装车过程监测', '问题管理', '绩效考评', '收发油分析', '大数据分析'], title: '油库智能安全生产管控软件', desc: '以油库基本设备设施及作业自动化为基础，依托油库控制网络和信息网络一体化构建，集成油库的工艺、消防、安防、设备等的现场实时数据，借助专业的大数据建模和数据分析技术，对油库进行运行监控、安全预警、操作规范性评价和数据分析应用，实现对油库安全生产运行的监控和管理。' },
            { list: ['指标设置', '公式设计', '数据集成', '指标计算', '指标考核', '指标分析', '指标改进',], title: '生产绩效管理软件', desc: '建立生产绩效考核体系，实现生产执行、设备、质量、安全、环保等生产相关绩效指标的设计、计算、发布、考核；涵盖包括公司级、车间级、装置级、岗位级等各级生产绩效管理，为实现班组操作绩效评价提供依据，通过考核促进生产管理的精细化提升。' },
            { list: ['频次样品管理', '临时采样管理', '数据采集和录入', '样品审核', '数据发布', '检验过程跟踪', '质量数据分析', '留样管理', '人员管理', '试剂管理', '库存管理', '设备管理', '通知提醒', '投诉管理', '仪器集成', '数据修约', '检验标准', '广播提醒'], title: '质量管理软件', desc: '建立全面质量管理体系，从原料进厂、产品加工、包装入库、产品出厂的全流程，对质量进行把控；生产岗位能够实时获取样品检验进度、质量检验结果、质量变化趋势，及时指导生产调整；质检岗位能够及时接收检验指令，加强生产与质检的业务协同和数据共享。' },
            { list: ['工艺档案管理', '工艺变更管理', '工艺指标库', '工艺操作监控报警', '工艺操作规程', '操作日志', '工艺台账', '装置开停工管理', '工艺偏差统计', '操作平稳率', '装置自控率', '联锁投用率', '操作绩效分析'], title: '工艺操作管理软件', desc: '建立企业工艺资料库，实现企业操作规程、工艺标准、工艺卡片等工艺技术资料的集中电子化管理；通过对工艺操作的实时监测，及时发现工艺偏差报警，为操作人员提供工艺操作规程指导，消除工艺偏差；基于实时的平稳率、联锁投用率等操作绩效指标计算和分析，以考核促进操作技能提升，优化装置操作，实现降本增效。' },
            { list: ['新产品研发管理', '配方管理', '工艺指标管理', '生产订单管理', '生产计划排程', '生产过程监测', '原料批次管理', '生产批次管理', '包装批次管理', '产品出厂管理', '客户投诉管理', '客户需求管理', '质量追溯管理'], title: '化工批次管理软件', desc: '针对固体化工、零部件等小批量、多批次、多品种切换、间歇式生产特点，将企业从产品研发、生产、到包装出厂、客户销售的全过程工艺和质量参数进行建模，实现对产品全过程信息的跟踪和问题追溯。' },
            { list: ['炼油生产排产', '化工生产排产', '原油调合生产排产', '成品油调合生产排产', '计划执行监控', '图形建模与模拟', '数据集成任务调度', '生产效益测算',], title: '调度排产优化软件', desc: '炼油生产排产优化： 炼油企业从原油入厂到装置生产的全流程作业调度与排产优化；涵盖原油接卸、原油管 输、原油调合、原油加工、装置生产安排、装置物料走向安排、组分 跟踪、系统平衡测算等。化工生产排产优化：化工生产综合调度的全流程优化排产，功能包含原料进厂、原料调合、装置生产、产品走向、组分跟踪、产品出厂等。装置 生产牌号加工切换安排，以及乙烯、丙烯、碳四、裂解汽油等中间产 品流向平衡优化建议方案。成品油调合排产优化： 支持多产品调合优化排产，为调合作业生成优化配方，支持线性、非线性、复杂和衍生属性调合，适用于汽油、柴油和燃料油等油品调合。' },
          ]
        },
        {
          key: 2,
          title: '中智工业集成优化软件',
          list: [
            { title: '复杂精馏装置实时优化', desc: '中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化' },
            { title: '蒸汽动力系统实时优化', desc: '中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化' },
            { title: '氢气系统实时优化', desc: '中智软创在英国曼彻斯特大学过程集成研究中心集成优化技术基础上，结合公司团队对于化工行业的行业理解及管理应用软件开发经验，针对中国市场应用，进行应用开发，升级产品，并保证本地化实施和长期售后服务，致力于国内集成优化技术的产业化' },
          ]
        },
      ],
      searchList: [],
      pageTotal: 0
    }
  },
  mounted() {
    this.searchList = this.list1[0].list
    this.pageTotal = this.searchList.length
    this.selectVal = localStorage.getItem('CISPROINFO')
    this.searchList = this.list1.filter(e => e.title === this.selectVal)[0].list
  },
  methods: {
    handleBack() {
      this.$router.go(-1)
    },
    handleCurrentChange(val) {
      this.pageIndex = val - 1
    },
    /* 
      类型选择
    */
    handleSelect(val) {
      this.searchList = this.list1.filter(e => e.title === val)[0].list
      this.value1 = ''
      this.selectVal = val
    },
    /* 
      关键字搜索
    */
    handleSearch(val) {
      let searchList = this.list1.filter(e => e.title === this.selectVal)[0].list
      this.searchList = searchList.filter(e => e.title.includes(val))
    },
    handleTo(val) {
      localStorage.setItem('CISNEWSINFO', val.title)
      this.$router.push({ path: 'production-info' }, val)
    }
  },
  watch: {
    // 利用watch方法检测路由变化：
    '$route': function (to, from) {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      console.log(to, from)
      window.location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 980px) {
  .banner {
    height: 450px;
    .back {
      padding-top: 100px;
      cursor: pointer;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.825em;
    }
    .title-1 {
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 56px;
      font-size: 1.825em;
      text-align: center;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 980px) {
  .banner {
    height: 350px;
    .back {
      padding-top: 100px;
      padding-left: 20px;
    }
    .title {
      font-family: PingFang-SC-Medium, PingFang-SC;
      color: #ffffff;
      line-height: 86px;
      font-size: 1.2em;
      padding-left: 20px;
    }
    .title-1 {
      font-family: PingFang-SC-Medium, PingFang-SC;
      color: #ffffff;
      padding-top: 50px;
      line-height: 56px;
      font-size: 1.2em;
      text-align: center;
    }
  }
}
.page_container {
  background-color: #fff;
  .banner {
    width: 100%;
    background: url("../../assets/solution/s-banner.png") no-repeat;
    background-size: cover;
    color: #fff;
    .back {
      cursor: pointer;
    }

    .desc-1 {
      text-align: center;
      margin-bottom: 45px;
    }
    .search {
      text-align: center;
    }
  }
  .icon {
    width: 38px;
    height: 38px;
  }
  .page1 {
    padding-bottom: 35px;

    .page1-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // &:hover {
      //   box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
      // }
      .title {
        font-size: 1.25em;
        line-height: 3em;
      }
      .desc {
        font-size: 0.825em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal;
      }
      .btn {
        display: inline-block;
        background: linear-gradient(90deg, #4e82fc 0%, #264bf8 100%);
        border-radius: 5px;
        padding: 5px 12px;
        cursor: pointer;
        color: #fff;
        font-size: 0.825em;
        line-height: 20px;
        margin: 20px 0;
      }
    }
    .image {
      width: 100%;
      height: 160px;
      display: block;
    }
  }
  .page_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-tab {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    .pro-tab {
      padding: 10px 15px;
      border-radius: 20px;
      width: 150px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
</style>